import React from "react"
import { Links, graphql, StaticQuery } from "gatsby"
import Layout from "src/components/layout"
import SubpageBanner from "src/components/SubpageBanner"
import SEO from "src/components/seo"
import SimpleText from "src/components/simple-text"
import Breadcrumbs from "src/components/Breadcrumbs"
import Sidebar from "src/components/Sidebar"

const breadcrumbs = [
  { url: "development", handle: "Development" },
  {
    url: "development/history",
    handle: "History",
  },
]

const History = () => (
  <StaticQuery
    query={graphql`
      query {
        one: file(relativePath: { eq: "history.jpg" }) {
          childImageSharp {
            fixed(quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        background: file(relativePath: { eq: "discarded-cells-banner.jpg" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => {
      const one = data.one.childImageSharp.fixed
      const background = data.background.childImageSharp.fluid

      return (
        <Layout>
          <SEO title="History" />
          <SubpageBanner
            image={background}
            heading="exCellerator HISTORY - Necessity Leads to Innovation"
          />
          <div className="wrapper wrapper--narrow p-bottom p-sides">
            <Breadcrumbs items={breadcrumbs} />
            <div className="split-contain wrapper">
              <div className="split-contain__sidebar">
                <Sidebar image={one} />
              </div>
              <div className="split-contain__contain">
                <h2>
                  <em>For the sake of Women’s Health</em>
                </h2>
                <SimpleText
                  heading="Facing a Challenge"
                  copy="Following the introduction of the ThinPrep Pap Test system, some clients began to experience and complain about results of specimen inadequacy from samples they contended were quality collections.  We knew that the new system was better overall, so we set out to find out what was causing the issue."
                />
                <SimpleText heading="Identifying the Problem">
                  <p>
                    Based on our initial assessment and broader industry
                    sentiment, we quickly identified sample transfer from the
                    cytobrush as the main issue. It was clear that the sample
                    would not consistently dislodge from the bristles of the
                    brush.
                  </p>
                  <p>
                    Basic testing of the cytobrush following standard use
                    confirmed that a significant amount of sample remained in
                    the brush bristles and was therefore being discarded along
                    with the device rather than being transferred into the vial.
                  </p>
                  <p>
                    Larger follow-up studies indicated significant and
                    consistent transfer issues.
                  </p>
                </SimpleText>
                <SimpleText heading="Developing a Solution">
                  <p>
                    It didn’t take long to find a simple and effective way to
                    help clinicians get the valuable sample they had worked hard
                    to collect. We found that simply adding a hole in the
                    spatula that would aid the user in teasing the sample out of
                    the bristles was the right solution.
                  </p>
                  <p>
                    Following development, large studies were conducted that
                    confirmed not only how much sample was normally left behind,
                    but more importantly, that we now had a way to capture it!
                  </p>
                </SimpleText>
                <SimpleText heading="Ongoing Validation">
                  <p>
                    Validation data has been tracked and evaluated across&nbsp;
                    <strong style={{ color: "#9C69AC" }}>
                      over 1 million samples.
                    </strong>
                    &nbsp;Analysis shows consistent and significant improvement
                    in adequacy, as well as significant gain in well-preserved
                    and valuable cells.
                  </p>
                </SimpleText>
              </div>
            </div>
          </div>
        </Layout>
      )
    }}
  />
)

export default History
